<div class="room-detail-container">
  <div class="image-price-container">
    <img [src]="room.imgPath" [alt]="room.name" />
    <div class="overlay"></div>
    <div class="price-container">
      <p class="price-text">{{ "Starts From".toUpperCase() }}</p>
      <p class="price">${{ room.price }}</p>
    </div>
  </div>
  <div class="detail-container">
    <div class="name-utility-container">
      <p class="room-name">{{ room.name.toUpperCase() }}</p>
      <hr />
      <div class="utility-container">
        <div class="utility-item">
          <div class="icon-row first-icon-row">
            <ion-icon
              class="icon"
              name="person"
              *ngFor="let guest of [].constructor(room.numberOfGuests)"
            ></ion-icon>
          </div>
          <p class="icon-text">
            {{ room.numberOfGuests }} Guest{{
              room.numberOfGuests > 1 ? "s" : ""
            }}
          </p>
        </div>
        <div class="utility-item">
          <div class="icon-row">
            <ion-icon
              class="icon"
              name="ios-bed"
              *ngFor="let bed of [].constructor(room.numberOfBeds)"
            ></ion-icon>
          </div>
          <p class="icon-text">
            {{ room.numberOfBeds }} Bed{{ room.numberOfBeds > 1 ? "s" : "" }}
          </p>
        </div>
        <div class="utility-item">
          <div class="icon-row">
            <ion-icon
              class="icon {{ room.hasWifi ? '' : 'disable' }}"
              name="ios-wifi"
            ></ion-icon>
          </div>
          <p class="icon-text">Wifi</p>
        </div>
        <div class="utility-item">
          <div class="icon-row">
            <ion-icon
              class="icon {{ room.hasTV ? '' : 'disable' }}"
              name="tv"
            ></ion-icon>
          </div>
          <p class="icon-text {{ room.hasTV ? '' : 'disable' }}">TV</p>
        </div>
      </div>
    </div>
    <app-button text="View Room" [routerLink]="path"></app-button>
  </div>
</div>
