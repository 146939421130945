<div class="container">
  <app-title title="Contact Us"></app-title>
  <div class="row contact-container">
    <div class="span-1-of-2 map-container">
      <google-map
        width="100%"
        height="100%"
        [center]="center"
        [zoom]="zoom"
        [options]="mapOptions"
      >
        <map-marker
          [position]="center"
          [title]="markerTitle"
        >
        </map-marker>
      </google-map>
    </div>
    <div class="span-1-of-2 info-container">
      <h2 *ngFor="let heading of headingList">{{ heading.toUpperCase() }}</h2>
      <ul class="contact-list">
        <li
          class="contact-item"
          *ngFor="let contactItem of contactItems; let i = index"
        >
          <ion-icon class="icon" name="{{ contactItem.icon }}"></ion-icon>
          <p class="contact-detail">{{ contactItem.detail }}</p>
        </li>
      </ul>
    </div>
  </div>
</div>
