import { Component, OnInit, OnDestroy } from "@angular/core";
import { NavigationItem } from "../../models/navigation-item.model";
import { Subscription } from 'rxjs';
import { NavigationService } from 'src/app/services/navgiation.service';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
})
export class NavbarComponent implements OnInit, OnDestroy {

  private navSub: Subscription;
  navItems: NavigationItem[];
  navOpen: Boolean = false;

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {
    this.navItems = this.navigationService.getNavigationItems();
    this.navSub = this.navigationService.getNavigationItemsUpdatedListener().subscribe(
      (navigationItems: NavigationItem[]) => {
        this.navItems = navigationItems;
      }
    );
  }

  onNavigationItemClick(index: number) {
    this.navigationService.setNavigationItem(index);
  }

  clearSelectedNavigationItems() {
    this.navigationService.clearSelectedNavigationItems();
  }

  ngOnDestroy() {
    this.navSub.unsubscribe();
  }

  onMenuClick() {
    this.navOpen = !this.navOpen;
  }
}
