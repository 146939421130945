import { Component, OnInit } from "@angular/core";
import { NavigationService } from "../services/navgiation.service";
import { ContactService } from "../services/contact.service";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent implements OnInit {
  contactItems: { letter: String; detail: String; icon: String }[];
  headingList: String[] = ["Want to make a booking?", "Get in touch with us!"];
  zoom = 18;
  center: google.maps.LatLngLiteral = {
    lat: -36.880582,
    lng: 174.793555,
  };
  mapOptions: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    maxZoom: 20,
    minZoom: 10,
  };
  markerTitle: String = "276 Remuera Road";

  constructor(
    private navigationService: NavigationService,
    private contactService: ContactService
  ) {}

  ngOnInit() {
    this.contactItems = this.contactService.getContactItems();
    const navigationItemIndex = this.navigationService.getNavigationItemIndex(
      "Contact"
    );
    this.navigationService.setNavigationItem(navigationItemIndex);
  }
}
