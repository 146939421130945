import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../services/navgiation.service';

@Component({
  selector: 'app-breakfast',
  templateUrl: './breakfast.component.html',
  styleUrls: ['./breakfast.component.css']
})
export class BreakfastComponent implements OnInit {

  constructor(private navigationService: NavigationService) { }

  ngOnInit() {
    const navigationItemIndex = this.navigationService.getNavigationItemIndex('Breakfast');
    this.navigationService.setNavigationItem(navigationItemIndex);
  }
}
