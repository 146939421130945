<div class="container">
  <div class="row">
    <app-button text="Back" [routerLink]="['/rooms']"></app-button>
  </div>
  <app-title [title]="room.name"></app-title>
  <div class="row main-container">
    <div class="image-container">
        <div class="circle-button previous-icon {{ imageIndex > 0 ? 'visible' : 'hide' }}">
            <ion-icon
          class="image-icon"
          name="arrow-back"
          (click)="previousImage()"
        ></ion-icon>
        </div>
      <img [src]="room.images[imageIndex]" [alt]="room.name" />
      <div class="circle-button next-icon {{ imageIndex < room.images.length - 1 ? 'visible' : 'hide' }}">
        <ion-icon
        class="image-icon"
        name="arrow-forward"
        (click)="nextImage()"
      ></ion-icon>
      </div>
    </div>
    <div class="price-utilities-container">
      <div class="price-container">
        <p class="price-text">{{ "Starts From".toUpperCase() }}</p>
        <p class="price">${{ room.price }}</p>
      </div>
      <div class="utility-container">
        <p class="utilities-title">Sleeping Arrangements</p>
        <div class="utility-item">
          <div class="icon-row">
            <ion-icon
              class="icon"
              name="person"
              *ngFor="let guest of [].constructor(room.numberOfGuests)"
            ></ion-icon>
          </div>
          <p class="icon-text">
            {{ room.numberOfGuests }} Guest{{
              room.numberOfGuests > 1 ? "s" : ""
            }}
          </p>
        </div>
        <div class="utility-item">
          <div class="icon-row">
            <ion-icon
              class="icon"
              name="ios-bed"
              *ngFor="let bed of [].constructor(room.numberOfBeds)"
            ></ion-icon>
          </div>
          <p class="icon-text">
            {{ room.numberOfBeds }} Bed{{ room.numberOfBeds > 1 ? "s" : "" }}
          </p>
        </div>
        <p class="utilities-title">Amenities</p>
        <div class="utility-item">
          <div class="icon-row">
            <ion-icon
              class="icon {{ room.hasWifi ? '' : 'disable' }}"
              name="ios-wifi"
            ></ion-icon>
          </div>
          <p class="icon-text">Wifi</p>
        </div>
        <div class="utility-item">
          <div class="icon-row">
            <ion-icon
              class="icon {{ room.hasTV ? '' : 'disable' }}"
              name="tv"
            ></ion-icon>
          </div>
          <p class="icon-text {{ room.hasTV ? '' : 'disable' }}">TV</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <hr />
    <p class="room-description">{{ room.description }}</p>
  </div>
</div>
