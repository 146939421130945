<div class="container">
  <app-title title="Image Gallery"></app-title>
  <div class="row grid">
    <div class="grid-item" *ngFor="let image of images; let i = index">
      <img
        class="preview"
        [src]="image.src"
        [alt]="image.alt"
        (click)="handleImageClick(i)"
      />
    </div>
  </div>

  <!-- Image View -->
  <div class="background" *ngIf="viewImage" (click)="closeImageView()">
    <div class="image-view-container" (click)="handleImageViewClick($event)">
      <div class="image-arrow-container">
        <ion-icon
          class="icon previous-icon {{ imageIndex > 0 ? 'visible' : 'hide' }}"
          name="arrow-back"
          (click)="previousImage()"
        ></ion-icon>
        <div class="image-container">
            <img
          class="image"
          [src]="images[imageIndex].src"
          [alt]="images[imageIndex].alt"
        />
        </div>
        <ion-icon
          class="icon next-icon {{ imageIndex < images.length - 1 ? 'visible' : 'hide' }}"
          name="arrow-forward"
          (click)="nextImage()"
        ></ion-icon>
      </div>
      <div class="image-count-container">
        <p class="count-text">{{ imageIndex + 1 }} / {{ images.length }}</p>
      </div>
    </div>
  </div>
</div>
