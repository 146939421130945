import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { GoogleMapsModule } from '@angular/google-maps';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { RoomsComponent } from './rooms/rooms.component';
import { ContactComponent } from './contact/contact.component';
import { GalleryComponent } from './gallery/gallery.component';
import { BreakfastComponent } from './breakfast/breakfast.component';
import { TitleComponent } from './shared/title/title.component';
import { NavigationService } from './services/navgiation.service';
import { ContactService } from './services/contact.service';
import { ButtonComponent } from './shared/button/button.component';
import { RoomDetailComponent } from './rooms/room-detail/room-detail.component';
import { AllRoomsComponent } from './rooms/all-rooms/all-rooms.component';
import { RoomComponent } from './rooms/room/room.component';
import { RoomsService } from './services/rooms.service';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  {
    path: 'rooms',
    component: RoomsComponent,
    children: [
      {
        path: '',
        component: AllRoomsComponent,
      },
      {
        path: ':name',
        component: RoomComponent,
      },
    ],
  },
  { path: 'breakfast', component: BreakfastComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'contact', component: ContactComponent },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    RoomsComponent,
    ContactComponent,
    GalleryComponent,
    BreakfastComponent,
    TitleComponent,
    ButtonComponent,
    RoomDetailComponent,
    AllRoomsComponent,
    RoomComponent,
  ],
  imports: [
    BrowserModule,
    IonicModule,
    GoogleMapsModule,
    RouterModule.forRoot(routes),
  ],
  providers: [NavigationService, ContactService, RoomsService],
  bootstrap: [AppComponent],
})
export class AppModule {}
