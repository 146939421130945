<div class="container">
  <app-title title="Continental Breakfast Menu (currently unavailable)"></app-title>
  <div class="row menu-container">
    <div class="breakfast-menu">
      <img src="../../assets/images/breakfast-menu.png" alt="Breakfast Menu" />
    </div>
  <!-- breakfast unavailable -->
  </div>
</div>


