<nav id="navbar">
  <div class="row">
    <img
      class="logo"
      src="../../../assets/images/devereux-logo.png"
      [routerLink]="['/home']"
      (click)="clearSelectedNavigationItems()"
    />
    <ion-icon
          class="icon menu"
          name="menu"
          *ngIf="!navOpen"
          (click)="onMenuClick()"
        ></ion-icon>
    <ion-icon
          class="icon close"
          name="close"
          *ngIf="navOpen"
          (click)="onMenuClick()"
        ></ion-icon>
    <ul class="nav-list">
      <li
        class="nav-item {{ navItem.isSelected ? 'selected' : '' }}"
        *ngFor="let navItem of navItems; let i = index"
        [routerLink]="navItem.path"
        (click)="onNavigationItemClick(i)"
      >
        {{ navItem.label }}
      </li>
    </ul>
    </div>
</nav>
<div class="background" *ngIf="navOpen">
  <ul class="nav-list-compact">
    <li
      class="nav-item-compact {{ navItem.isSelected ? 'selected' : '' }}"
      *ngFor="let navItem of navItems; let i = index"
      [routerLink]="navItem.path"
      (click)="onNavigationItemClick(i); onMenuClick()"
    >
      {{ navItem.label }}
    </li>
  </ul>
</div>
