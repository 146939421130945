<footer>
  <div class="row contact-container">
    <img
      class="logo"
      src="../../../assets/images/devereux-logo.png"
      [routerLink]="['/home']"
      (click)="clearSelectedNavigationItems()"
    />
    <ul class="contact-list">
      <li class="contact-item" *ngFor="let contactItem of contactItems">
        <b>{{ contactItem.letter }}</b>
        <p>{{ contactItem.detail }}</p>
      </li>
    </ul>
  </div>
  <div class="row copyright-container">
    <p class="copyright">
      All Rights Reserved. © 2020 The Devereux Remuera Ltd.
    </p>
  </div>
</footer>
