import { Component, OnInit } from "@angular/core";
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  aboutText: String =
    "This 1890’s historical villa The Devereux Boutique Hotel is centrally situated in the best part of Auckland. " +
    "It is tastefully and stylishly decorated for the comfort and needs of our clientele. Our hotel is located in " +
    "Remuera suburb, Auckland. It is away from the busy and crowded city but still surrounded by the amazing array " +
    "of restaurants, bars and retails shops that Auckland has to offer. Easy access to many places or attractions. " +
    "There are many great cafes, restaurants and supermarkets just minutes walk from the hotel, there is also only " +
    "15 minutes drive from the CBD, 30 minutes drive from the Airport, 5 minutes drive to Newmarket shopping centre, " +
    "10 minutes to Parnell, 7 minutes drive to Cornwall Park and One Tree Hill, 10 minutes drive to Mt Eden Village, " +
    "15 minutes to Ponsonby,10-15 minutes drive to many events venue and race course, 20 minutes walk to Mt Hobson. " +
    "Bus stop only few steps away. Staying in one of the 13 unique and beautifully designed guest rooms, you will " +
    "experience genuine privacy and comfort designed for the discerning traveller and executives. Most rooms have " +
    "ensuite bathroom, except the Economy Rooms are private rooms with shared bathroom. Our hotel also provide free " +
    "wiﬁ, ﬂat screen TV with sky, kettle, refreshments, refrigerator, heater, electric blanket and certain rooms have " +
    "spa bath. All our 13 rooms come with different names and different themes, every stay is different for our guests. " +
    "Guests can also enjoy the relaxing atmosphere in the open lounge area and enjoy fresh cooked breakfast daily in our " +
    "dinning area that over looking our personalised garden. During winter, the ﬁre place in the open lounge area will " +
    "light up and offer you a more comfortable atmosphere. We offer spaces for small meetings or conference equipped with " +
    "high speed wireless internet access. Your comfort and peace of mind in our tranquil surroundings is of great importance " +
    "to us. We look forward to meeting you and being of service. Your home away from home.";

  displayRooms: { src: String; alt: String; name: String; path: String[] }[] = [
    {
      src: "../../assets/images/zambezi-1.jpg",
      alt: "Zambezi 1",
      name: "Zambezi",
      path: ["/rooms", "zambezi"],
    },
    {
      src: "../../assets/images/antigua-1.jpg",
      alt: "Antigua 1",
      name: "Antigua",
      path: ["/rooms", "antigua"],
    },
    {
      src: "../../assets/images/tuscany-1.jpg",
      alt: "Tuscany 1",
      name: "Tuscany",
      path: ["/rooms", "tuscany"],
    },
    {
      src: "../../assets/images/taj-1.jpg",
      alt: "Taj 1",
      name: "Taj",
      path: ["/rooms", "taj"],
    },
  ];

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }
}
