import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/services/navgiation.service';
import { Room } from 'src/app/models/room.model';
import { RoomsService } from 'src/app/services/rooms.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-all-rooms',
  templateUrl: './all-rooms.component.html',
  styleUrls: ['./all-rooms.component.css']
})
export class AllRoomsComponent implements OnInit {
  deluxeSuites: Room[];
  executiveSuites: Room[];
  standardSuites: Room[];
  familySuites: Room[];
  economyWithSharedRoom: Room[];
  gardenStudio: Room[];

  constructor(private navigationService: NavigationService, private roomsService: RoomsService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
    const navigationItemIndex = this.navigationService.getNavigationItemIndex(
      "Rooms"
    );
    this.navigationService.setNavigationItem(navigationItemIndex);
    this.deluxeSuites = this.roomsService.getDeluxeSuites();
    this.executiveSuites = this.roomsService.getExecutiveSuites();
    this.standardSuites = this.roomsService.getStandardSuites();
    this.familySuites = this.roomsService.getFamilySuites();
    this.economyWithSharedRoom = this.roomsService.getEconomyWithSharedRoom();
    this.gardenStudio = this.roomsService.getGardenStudio();
  }

}
