import { Component, OnInit } from "@angular/core";
import { NavigationService } from "../services/navgiation.service";

@Component({
  selector: "app-gallery",
  templateUrl: "./gallery.component.html",
  styleUrls: ["./gallery.component.css"],
})
export class GalleryComponent implements OnInit {
  images: { src: String; alt: String }[] = [
    { src: "../../assets/images/hotel-side-front.jpg", alt: "Devereux Hotel 1" },
    { src: "../../assets/images/hotel-back-garden.jpg", alt: "Devereux Garden 1" },
    { src: "../../assets/images/zambezi-1.jpg", alt: "Zambezi 1" },
    { src: "../../assets/images/zambezi-2.jpg", alt: "Zambezi 2" },
    { src: "../../assets/images/antigua-1.jpg", alt: "Antigua 1" },
    { src: "../../assets/images/tuscany-1.jpg", alt: "Tuscany 1" },
    { src: "../../assets/images/taj-1.jpg", alt: "Taj 1" },
    { src: "../../assets/images/oreint-1.jpg", alt: "Orient 1" },
    { src: "../../assets/images/rangitoto-1.jpg", alt: "Rangitoto 1" },
    { src: "../../assets/images/south-pacific-1.jpg", alt: "South Pacific 1" },
    { src: "../../assets/images/cairo-1.jpg", alt: "Cairo 1" },
    { src: "../../assets/images/bodrum-1.jpg", alt: "Bodrum 1" },
    { src: "../../assets/images/provence-1.jpg", alt: "Provence 1" },
    { src: "../../assets/images/family-suite-1.jpg", alt: "Family Suite 1" },
    { src: "../../assets/images/family-suite-2.jpg", alt: "Family Suite 2" },
    { src: "../../assets/images/family-suite-3.jpg", alt: "Family Suite 3" },
    { src: "../../assets/images/family-suite-4.jpg", alt: "Family Suite 4" },
    { src: "../../assets/images/guadalope-1.jpg", alt: "Guadalope 1" },
    { src: "../../assets/images/kinloch-1.jpg", alt: "Kinloch 1" },
    { src: "../../assets/images/garden-studio-1.jpg", alt: "Garden Studio 1" },
  ];
  viewImage: boolean = false;
  imageIndex: number;

  constructor(private navigationService: NavigationService) {}

  ngOnInit() {
    const navigationItemIndex = this.navigationService.getNavigationItemIndex(
      "Gallery"
    );
    this.navigationService.setNavigationItem(navigationItemIndex);
  }

  nextImage() {
    if (this.imageIndex < this.images.length - 1) {
      this.imageIndex++;
    }
  }

  previousImage() {
    if (this.imageIndex > 0) {
      this.imageIndex--;
    }
  }

  handleImageClick(index: number) {
    this.viewImage = true;
    this.imageIndex = index;
  }

  closeImageView() {
    this.viewImage = false;
  }

  handleImageViewClick(event: Event) {
    event.stopPropagation();
  }
}
