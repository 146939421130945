import { Component, OnInit, Input } from '@angular/core';
import { Room } from 'src/app/models/room.model';

@Component({
  selector: 'app-room-detail',
  templateUrl: './room-detail.component.html',
  styleUrls: ['./room-detail.component.css']
})
export class RoomDetailComponent implements OnInit {
  @Input() room: Room;
  path: String[];

  constructor() { }

  ngOnInit() {
    this.path = [`${this.room.name.toLowerCase()}`];
  }

}
