<div class="container">
    <app-title title="Master Suites"></app-title>
    <div class="row grid">
        <app-room-detail *ngFor="let room of deluxeSuites" [room]="room"></app-room-detail>
    </div>
    <app-title title="Executive Suites"></app-title>
    <div class="row grid">
        <app-room-detail *ngFor="let room of executiveSuites" [room]="room"></app-room-detail>
    </div>
    <app-title title="Standard Suites"></app-title>
    <div class="row grid">
        <app-room-detail *ngFor="let room of standardSuites" [room]="room"></app-room-detail>
    </div>
    <app-title title="Family Suites"></app-title>
    <div class="row grid">
        <app-room-detail *ngFor="let room of familySuites" [room]="room"></app-room-detail>
    </div>
    <app-title title="Economy Room With Shared Room"></app-title>
    <div class="row grid">
        <app-room-detail *ngFor="let room of economyWithSharedRoom" [room]="room"></app-room-detail>
    </div>
    <app-title title="Garden Studio (separate building)"></app-title>
    <div class="row grid">
        <app-room-detail *ngFor="let room of gardenStudio" [room]="room"></app-room-detail>
    </div>
</div>
