import { Injectable } from "@angular/core";
import { NavigationItem } from '../models/navigation-item.model';
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class NavigationService {
    private navigationItems: NavigationItem[] = [
        {
          label: "Rooms",
          path: ["/rooms"],
          isSelected: false,
        },
        {
          label: "Breakfast",
          path: ["/breakfast"],
          isSelected: false,
        },
        {
          label: "Gallery",
          path: ["/gallery"],
          isSelected: false,
        },
        {
          label: "Contact",
          path: ["/contact"],
          isSelected: false,
        },
      ];

    private isNavigationBarVisible: boolean = true;

    private navigationItemsUpdated = new Subject<NavigationItem[]>();
    private navigationBarVisibilityUpdated = new Subject<boolean>();

    getNavigationItemsUpdatedListener() {
        return this.navigationItemsUpdated.asObservable();
    }

    getNavigationBarVisibilityUpdatedListener() {
        return this.navigationBarVisibilityUpdated.asObservable();
    }

    getNavigationItems() {
        return this.navigationItems.slice();
    }

    getNavigationItemIndex(label: String) {
        return this.navigationItems.findIndex((navigationItem: NavigationItem) => navigationItem.label == label);
    }

    clearSelectedNavigationItems() {
        this.navigationItems.forEach((navigationItem: NavigationItem) => navigationItem.isSelected = false);
        this.navigationItemsUpdated.next(this.navigationItems.slice());
    }

    setNavigationItem(index: number) {
        this.clearSelectedNavigationItems();
        this.navigationItems[index].isSelected = true;
        this.navigationItemsUpdated.next(this.navigationItems.slice());
    }

    setNavgiationBarVisibility(status: boolean) {
        this.isNavigationBarVisible = status;
        this.navigationBarVisibilityUpdated.next(this.isNavigationBarVisible);
    }



}