import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import { Room } from 'src/app/models/room.model';
import { RoomsService } from 'src/app/services/rooms.service';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.css']
})
export class RoomComponent implements OnInit {
  room: Room;
  imageIndex = 0;

  constructor(private route: ActivatedRoute, private roomsService: RoomsService, private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
    this.route.params.subscribe(params => {
      const roomName = params['name'];
      this.room = this.roomsService.getRoom(roomName);
    });
  }

  nextImage() {
    if (this.imageIndex < this.room.images.length - 1) {
      this.imageIndex++;
    }
  }

  previousImage() {
    if (this.imageIndex > 0) {
      this.imageIndex--;
    }
  }
}
