import { Injectable } from "@angular/core";
import { Room } from "../models/room.model";

@Injectable()
export class RoomsService {
  private deluxeSuites: Room[] = [
    {
      name: "Zambezi",
      price: 179,
      imgPath: "../../assets/images/zambezi-1.jpg",
      images: [
        "../../assets/images/zambezi-1.jpg",
        "../../assets/images/zambezi-2.jpg",
      ],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 2,
      numberOfGuests: 3,
      description:
        "The spacious suite features large windows and a spa bath.\n\n" +
        "The spacious suite features large windows and is with a flat screen TV, mini fridge and kettle. All linen is provided, as well " +
        "as ironing facilities. All rooms have free access to our high-speed Wi-Fi.\n\n" +
        "Please note that the price is based on 2 guests using the double bed only. There is an additional single bed available at an " +
        "additional charge of NZD 10 per night.",
    },
    {
      name: "Antigua",
      price: 169,
      imgPath: "../../assets/images/antigua-1.jpg",
      images: ["../../assets/images/antigua-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 2,
      numberOfGuests: 3,
      description:
        "The spacious suite features large windows and is with a flat screen TV, mini fridge and kettle. All linen is provided, as well " +
        "as ironing facilities. All rooms have free access to our high-speed Wi-Fi.\n\n" +
        "Please note that the price is based on 2 guests using the double bed only. There is an additional single bed available at an " +
        "additional charge of NZD 10 per night.",
    },
    {
      name: "Tuscany",
      price: 169,
      imgPath: "../../assets/images/tuscany-1.jpg",
      images: ["../../assets/images/tuscany-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 2,
      numberOfGuests: 3,
      description:
        "The spacious suite features large windows and is with a flat screen TV, mini fridge and kettle. All linen is provided, as well " +
        "as ironing facilities. All rooms have free access to our high-speed Wi-Fi.\n\n" +
        "Please note that the price is based on 2 guests using the double bed only. There is an additional single bed available at an " +
        "additional charge of NZD 10 per night.",
    },
    {
      name: "Taj",
      price: 169,
      imgPath: "../../assets/images/taj-1.jpg",
      images: ["../../assets/images/taj-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 2,
      numberOfGuests: 3,
      description:
        "The spacious suite features large windows and is with a flat screen TV, mini fridge and kettle. All linen is provided, as well " +
        "as ironing facilities. All rooms have free access to our high-speed Wi-Fi.\n\n" +
        "Please note that the price is based on 2 guests using the double bed only. There is an additional single bed available at an " +
        "additional charge of NZD 10 per night.",
    },
  ];

  private executiveSuites: Room[] = [
    {
      name: "Oreint",
      price: 159,
      imgPath: "../../assets/images/oreint-1.jpg",
      images: ["../../assets/images/oreint-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 2,
      numberOfGuests: 3,
      description:
        "Our Executive suites feature a TV, mini fridge and spacious seating area. All linen is provided, as well as ironing facilities. " +
        "All rooms have free access to our high-speed Wi-Fi.\n\n" +
        "Each room includes an en suite bathroom featuring a shower, toilet, hairdryer and full set of towels.\n\n" +
        "There is an additional single bed available at an additional charge of NZD 10 per night.\n\n" +
        "Please note, the room is on the upper floor and accessible by stairs only.",
    },
    {
      name: "Rangitoto",
      price: 159,
      imgPath: "../../assets/images/rangitoto-1.jpg",
      images: ["../../assets/images/rangitoto-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 2,
      numberOfGuests: 3,
      description:
        "Our Executive suites feature a TV, mini fridge and spacious seating area. All linen is provided, as well as ironing facilities. " +
        "All rooms have free access to our high-speed Wi-Fi.\n\n" +
        "Each room includes an en suite bathroom featuring a shower, toilet, hairdryer and full set of towels.\n\n" +
        "There is an additional single bed available at an additional charge of NZD 10 per night.\n\n" +
        "Please note, the room is on the upper floor and accessible by stairs only.",
    },
  ];

  private standardSuites: Room[] = [
    {
      name: "South Pacific",
      price: 139,
      imgPath: "../../assets/images/south-pacific-1.jpg",
      images: ["../../assets/images/south-pacific-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 1,
      numberOfGuests: 2,
      description:
        "Our Standard Suites feature a TV, mini fridge and spacious seating area. All linen is provided, as well as ironing facilities. " +
        "All rooms have free access to our high-speed Wi-Fi.\n\n" +
        "Each room includes an en suite bathroom featuring a shower, toilet, hairdryer and full set of towels.",
    },
    {
      name: "Cairo",
      price: 139,
      imgPath: "../../assets/images/cairo-1.jpg",
      images: ["../../assets/images/cairo-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 1,
      numberOfGuests: 2,
      description:
        "Our Standard Suites feature a TV, mini fridge and spacious seating area. All linen is provided, as well as ironing facilities. " +
        "All rooms have free access to our high-speed Wi-Fi.\n\n" +
        "Each room includes an en suite bathroom featuring a shower, toilet, hairdryer and full set of towels.",
    },
    {
      name: "Bodrum",
      price: 139,
      imgPath: "../../assets/images/bodrum-1.jpg",
      images: ["../../assets/images/bodrum-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 1,
      numberOfGuests: 2,
      description:
        "Our Standard Suites feature a TV, mini fridge and spacious seating area. All linen is provided, as well as ironing facilities. " +
        "All rooms have free access to our high-speed Wi-Fi.\n\n" +
        "Each room includes an en suite bathroom featuring a shower, toilet, hairdryer and full set of towels.",
    },
    {
      name: "Provence",
      price: 139,
      imgPath: "../../assets/images/provence-1.jpg",
      images: ["../../assets/images/provence-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 1,
      numberOfGuests: 2,
      description:
        "Our Standard Suites feature a TV, mini fridge and spacious seating area. All linen is provided, as well as ironing facilities. " +
        "All rooms have free access to our high-speed Wi-Fi.\n\n" +
        "Each room includes an en suite bathroom featuring a shower, toilet, hairdryer and full set of towels.",
    },
  ];

  private familySuites: Room[] = [
    {
      name: "Family Suite",
      price: 190,
      imgPath: "../../assets/images/family-suite-1.jpg",
      images: [
        "../../assets/images/family-suite-1.jpg",
        "../../assets/images/family-suite-2.jpg",
        "../../assets/images/family-suite-3.jpg",
        "../../assets/images/family-suite-4.jpg",
      ],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 2,
      numberOfGuests: 3,
      description:
        "This room features 2 bedrooms with a lounge area, leather sofa, flat-screen TV, microwave, tea and coffee making facilities, " +
        "kettle, table and chair. \n" +
        "Your private bathroom includes a shower, toilet, hairdryer and full sets of towels.\n" +
        "There is access to a private front garden area with outdoor table.",
    },
  ];

  private economyWithSharedRoom: Room[] = [
    {
      name: "Guadalupe",
      price: 99,
      imgPath: "../../assets/images/guadalope-1.jpg",
      images: ["../../assets/images/guadalope-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 0,
      numberOfBeds: 2,
      numberOfGuests: 2,
      description:
        "Guadalupe features two single beds and a shared bathroom and toilet. The room has a flat-screen TV, " +
        "tea, coffee and milk, kettle, table and chair. All rooms have free access to our high speed Wi-Fi.",
    },
    {
      name: "Kinloch",
      price: 99,
      imgPath: "../../assets/images/kinloch-1.jpg",
      images: ["../../assets/images/kinloch-1.jpg"],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 0,
      numberOfBeds: 1,
      numberOfGuests: 1,
      description:
        "Kinloch features a King Single bed with a shared bathroom and toilet. The room has a flat-screen TV, " +
        "tea, coffee and milk, kettle, table and chair. All rooms have free access to our high speed Wi-Fi.",
    },
  ];

  private gardenStudio: Room[] = [
    {
      name: "Garden Studio",
      price: 189,
      imgPath: "../../assets/images/garden-studio-1.jpg",
      images: ["../../assets/images/garden-studio-1.jpg", "../../assets/images/garden-studio-2.jpg", "../../assets/images/garden-studio-3.jpg",
      "../../assets/images/garden-studio-4.jpg", "../../assets/images/garden-studio-5.jpg", "../../assets/images/garden-studio-6.jpg",
    ],
      hasWifi: true,
      hasTV: true,
      numberOfBathrooms: 1,
      numberOfBeds: 1,
      numberOfGuests: 2,
      description:
        "The Garden Studio features a King Double Bed with a private mini kitchen and bathroom, " +
        "the studio includes a Smart TV, as well as access to our free Wi-Fi.",
    },
    {
      name: "Economy Garden Studio",
      price: 139,
      imgPath: "../../assets/images/garden-studio-1.jpg",
      images: ["../../assets/images/garden-studio-1.jpg", "../../assets/images/garden-studio-2.jpg", "../../assets/images/garden-studio-3.jpg",
      "../../assets/images/garden-studio-4.jpg", "../../assets/images/garden-studio-5.jpg", "../../assets/images/garden-studio-6.jpg",
    ],
      hasWifi: true,
      hasTV: false,
      numberOfBathrooms: 1,
      numberOfBeds: 1,
      numberOfGuests: 2,
      description:
        "The Garden Studio features a Queen Double Bed with a private mini kitchen and bathroom, " +
        "as well as access to our free Wi-Fi.",
    },

  ];

  getDeluxeSuites() {
    return this.deluxeSuites.slice();
  }

  getExecutiveSuites() {
    return this.executiveSuites.slice();
  }

  getStandardSuites() {
    return this.standardSuites.slice();
  }

  getFamilySuites() {
    return this.familySuites.slice();
  }

  getEconomyWithSharedRoom() {
    return this.economyWithSharedRoom.slice();
  }

  getGardenStudio() {
    return this.gardenStudio.slice();
  }

  getRoom(name: String) {
    let room: Room;

    const allRooms: Room[] = this.deluxeSuites.concat(
      this.executiveSuites,
      this.standardSuites,
      this.familySuites,
      this.economyWithSharedRoom,
      this.gardenStudio
    );

    allRooms.forEach((r) => {
      if (r.name.toLowerCase() == name) {
        room = r;
        return room;
      }
    });

    return room;
  }
}
