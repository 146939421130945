<div class="header"></div>
<div class="section about-section">
    <app-title title="The Devereux Boutique Hotel"></app-title>
    <div class="row">
        <p class="about-text">
            {{ aboutText }}
        </p>
    </div>
</div>
<div class="section rooms-section">
    <app-title title="Devereux Hotel Rooms"></app-title>
    <div class="row grid">
        <div class="grid-item" *ngFor="let room of displayRooms" [routerLink]="room.path">
            <img [src]="room.src" [alt]="room.alt">
            <div class="overlay"></div>
            <p class="room-name">{{ room.name.toUpperCase() }}</p>
        </div>
    </div>
    <div class="row button-container">
        <app-button text="View All Rooms" [routerLink]="'/rooms'"></app-button>
    </div>
</div>
<!--<div class="section">-->
<!--    <div class="row button-container">-->
<!--        <app-button text="View Breakfast Menu" [routerLink]="'/breakfast'"></app-button>-->
<!--    </div>-->
<!--</div>-->
