import { Injectable } from "@angular/core";
import { Subject } from 'rxjs/internal/Subject';

@Injectable()
export class ContactService {
    private contactItems: { letter: String, detail: String, icon: String }[] = [
        { letter: "T", detail: "+64 (09) 5245044 | 021 861 682", icon: "call" },
        { letter: "E", detail: "bookings@thedevereux.co.nz", icon: "mail" },
        { letter: "A", detail: "267 Remuera Road, Auckland, New Zealand", icon: "navigate" },
      ];

    getContactItems() {
        return this.contactItems.slice();
    }
}
