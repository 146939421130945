import { Component, OnInit } from "@angular/core";
import { NavigationService } from 'src/app/services/navgiation.service';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  contactItems: { letter: String; detail: String, icon: String }[];

  constructor(private navigationService: NavigationService, private contactService: ContactService) {}

  ngOnInit() {
    this.contactItems = this.contactService.getContactItems();
  }

  clearSelectedNavigationItems() {
    this.navigationService.clearSelectedNavigationItems();
  }
}
